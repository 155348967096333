var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container rows" },
    [
      _c("OfferForm", {
        attrs: { offer: _vm.offer, editOfferFlag: false },
        on: {
          redirectToOffers: function($event) {
            return _vm.$router.push("/sadmin/offers")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }